<template>
	<div class="earningNo">
		<div class="hd">
			{{i18n('250')}}
		</div>
		<div class="inn">
			<div class="left">
				<img src="@/assets/kc.png" alt="">
			</div>
			<div class="right">
				<div class="top">
					<div class="">
						{{i18n('251')}}
					</div>
					<div class="">
						<span>₱</span> {{userInfo.balance || 0}}
					</div>
				</div>
				<div class="bot">
					<div class="">
						{{i18n('252')}}
					</div>
					<div class="" @click="agentUp">
						{{i18n('253')}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		agentUp
	} from "@/api/agent.js"
	export default {
		name: "earningNo",
		components: {

		},
		data() {
			return {

			}
		},
		methods: {
			async agentUp() {
				await agentUp()
				this.$emit('openSuccess')
				this.$store.dispatch('user/getUserInfo')
			}
		},
		computed: {
			userInfo() {
				return this.$store.state.user.userInfo
			}
		},
		created() {

		},
		mounted() {

		}
	};
</script>

<style scoped>
	.inn {
		display: flex;
		align-items: center;
	}

	.inn .left {
		margin-right: 24px;
	}

	.inn .left img {
		width: 360px;
		height: 160px;
		display: block;
	}

	.inn .right {
		flex: 1;
	}

	.inn .right .top {
		padding-bottom: 8px;
		border-bottom: 1px solid #56AB7B;
	}

	.inn .right .top div:nth-child(1) {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #56AB7B;
		font-weight: 400;
	}

	.inn .right .top div:nth-child(2) {
		font-family: Helvetica;
		font-size: 18px;
		color: #F23725;
		font-weight: 400;
		padding-top: 5px;
	}

	.inn .right .top div:nth-child(2) span {
		font-size: 12px;
	}

	.inn .right .bot {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 14px;
	}

	.inn .right .bot div:nth-child(1) {
		width: 299px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #56AB7B;
		font-weight: 400;
	}

	.inn .right .bot div:nth-child(2) {
		width: 260px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		line-height: 44px;
		cursor: pointer;
	}

	.earningNo {
		padding: 0 24px 34px 24px;
	}

	.hd {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
		line-height: 52px;
	}
</style>
