<template>
	<div class="FQ">
		<div class="item">
			<div class="tit">
				<span>*</span> {{i18n('257')}}
			</div>
			<div class="p">
				{{i18n('258')}}<br>
				{{i18n('259')}}
			</div>
		</div>
		<div class="item">
			<div class="tit">
				<span>*</span> {{i18n('260')}}
			</div>
			<div class="p">
				{{i18n('261')}}<br>
				{{i18n('262')}}<br>
				{{i18n('263')}}<br>
				2.APP<br>
				{{i18n('264')}}<br>
				{{i18n('265')}}
			</div>
		</div>
		<div class="item">
			<div class="tit">
				<span>*</span> {{i18n('266')}}
			</div>
			<div class="p">
				{{i18n('267')}}<br>
				{{i18n('268')}}<br>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "FQ",
		data() {
			return {

			}
		},
		methods: {

		},
		created() {

		},
		mounted() {

		}
	};
</script>

<style scoped>
	.FQ{
		padding: 0 24px;
	}
	.FQ .item{
		padding: 24px 0;
		border-bottom: 1px solid rgba(232,232,232,1);
	}
	.FQ .item:last-child{
		border-bottom: none;
	}
	.FQ .item .tit{
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #1D2129;
		line-height: 24px;
		font-weight: 500;
		margin-bottom: 16px;
	}
	.FQ .item .tit span{
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #56AB7B;
		line-height: 24px;
		font-weight: 500;
	}
	.FQ .item .p{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		line-height: 20px;
		font-weight: 400;
	}
</style>
