<template>
	<div class="invitation">
		<div class="title">
			{{i18n('87')}}
		</div>
		<div class="inner">
			<div class="one">
				<div class="tit">
					<div class="left">
						{{i18n('273')}}
					</div>
					<div class="right" v-if="isOpen" @click="$router.push('/myEarning')">
						{{i18n('121')}}
					</div>
				</div>
				<earningYes v-if="isOpen"></earningYes>
				<earningNo v-if="!isOpen" @openSuccess="agentInfo"></earningNo>
			</div>
			<div class="one two">
				<div class="tit">
					<div class="left">
						{{i18n('274')}}
					</div>
					<div class="right" v-if="isOpen" @click="$router.push('/myClient')">
						{{i18n('275')}}
					</div>
				</div>
				<InviteFriends :invitation_code="invitation_code" :isOpen="isOpen" :info="info"></InviteFriends>
			</div>
			<div class="one three">
				<div class="tit">
					<div class="left">
						F&Q
					</div>
					<div class="right">
						
					</div>
				</div>
				<FQ></FQ>
			</div>
		</div>
	</div>
</template>

<script>
	import {agentInfo} from "@/api/agent.js"
	
	import earningNo from "./components/earningNo.vue"
	import earningYes from "./components/earningYes.vue"
	import InviteFriends from "./components/InviteFriends.vue"
	import FQ from "./components/FQ.vue"
	export default {
		name: "invitation",
		components:{
			earningNo,
			earningYes,
			InviteFriends,
			FQ
		},
		data() {
			return {
				info:false,
				invitation_code:''
			}
		},
		methods: {
			async agentInfo(){
				let {data} = await agentInfo()
				this.info = data
				this.invitation_code = data.invitation_code
			}
		},
		computed:{
			isOpen(){
				if(this.$store.state.user.userInfo.type==3){
					return true
				}else{
					return false
				}
			},
			userInfo(){
				return this.$store.state.user.userInfo
			}
		},
		created() {
			if(this.isOpen){
				this.agentInfo()
			}
		},
		mounted() {
			
		}
	};
</script>

<style scoped>
	.one{
		width: 1032px;
		background: #FFFFFF;
		border-radius: 12px;
		margin-bottom: 16px;
	}
	.tit{
		padding: 0 24px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #56AB7B;
	}
	.tit .left{
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		font-weight: 500;
	}
	.tit .right{
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #56AB7B;
		text-align: right;
		font-weight: 500;
		cursor: pointer;
	}
	.inner {
		flex: 1;
		overflow-y: scroll;
	}
	
	.title {
		height: 69px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1D2129;
		line-height: 69px;
		font-weight: 500;
	}
	
	.invitation {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow-y: scroll;
	}
</style>