<template>
	<div class="InviteFriends">
		<div class="one">
			<div class="item">
				<div class="inn">
					<div class="" style="color: #F23725;">
						{{!isOpen?'0.5':info.sub_commission}}%
					</div>
					<div class="">
						{{i18n('269')}}
					</div>
				</div>
			</div>
			<div class="item">
				<div class="inn">
					<div class="" style="color: #F23725;">
						{{i18n('270')}}
					</div>
					<div class="">
						{{i18n('271')}}
					</div>
				</div>
			</div>
		</div>
		<div class="two">
			<div class="left items">
				<img src="@/assets/loginRegistration/bg.png" alt="">
			</div>
			<div class="right items">
				<div class="inn">
					<div class="">
						<div class="top">
							{{i18n('272')}}
						</div>
						<div class="bot" ref="qrCodeUrl">

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2'
	export default {
		name: "InviteFriends",
		props: ['isOpen', 'info', 'invitation_code'],
		data() {
			return {
				url: 'https://h5.yeahfresh.ph/#/pages/login/login?invitation_code='
			}
		},
		watch: {
			invitation_code() {
				this.creatQrCode()
			}
		},
		methods: {
			creatQrCode() {
				if (this.invitation_code != '') {
					this.url = process.env.VUE_APP_BASE_H5+'/loginRegistration?type=2&invitation_code=' + this.invitation_code
				} else {
					this.url = process.env.VUE_APP_BASE_H5+'/loginRegistration?type=2'
				}
				let _this = this;
				new QRCode(this.$refs.qrCodeUrl, {
					text: _this.url, // 需要转换为二维码的内容
					width: 160,
					height: 160,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			},
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			},
			userInfo() {
				return this.$store.state.user.userInfo
			}
		},
		created() {

		},
		mounted() {
			if(this.userInfo.type==3){
				if(this.invitation_code!=''){
					this.creatQrCode()
				}
			}else{
				this.creatQrCode()
			}
			
		}
	};
</script>

<style scoped>
	.two .right .inn {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.two .right .inn .top {
		width: 182px;
		height: 32px;
		background: #56AB7B;
		border-radius: 12px 12px 0px 0px;
		text-align: center;
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #FFFFFF;
		font-weight: 400;
		line-height: 32px;
	}

	.two .right .inn .bot {
		width: 180px;
		height: 180px;
		border: 1px solid rgba(201, 205, 212, 1);
		border-radius: 0px 0px 12px 12px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.two {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.two .left img {
		width: 348px;
		height: 245px;
		display: block;
	}

	.two .items {
		width: 480px;
		height: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.one {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.one .item {
		width: 480px;
		height: 140px;
		background: rgba(86, 171, 123, 0.10);
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.one .item .inn div:nth-child(1) {
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #4E5969;
		text-align: center;
		font-weight: 500;
	}

	.one .item .inn div:nth-child(2) {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #56AB7B;
		font-weight: 400;
		text-align: center;
		margin-top: 13px;
	}

	.InviteFriends {
		padding: 24px 24px 0 24px;
	}
</style>
